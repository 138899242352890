.react-calendar {
    max-width: 500px;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    font-size: 1.5rem;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #9595A5;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #9595A5;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 14px 10.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 1.0rem;
    position: relative;
}

.react-calendar__tile span {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #813400;
}

.react-calendar__tile--now {
    /* background: #ffff76; */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    /* background: #ffffa9; */
}

.react-calendar__tile--hasActive {
    background: #FF6900;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #FF6900;
}

.react-calendar__tile--active {
    /* background: #006edc; */
    background: #200800;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}